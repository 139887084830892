.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Brutal Type", sans-serif;
  font-size: 32px;
  letter-spacing: 2px;
  height: 100vh;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 736px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1023px) {
  .container {
    width: 768px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .container {
    width: 800px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .container {
    width: 800px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .container {
    width: 1024px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .container {
    width: 1200px;
  }
}
@media screen and (min-width: 1919px) and (max-width: 2560px) {
  .container {
    width: 1367px;
  }
}
@media screen and (min-width: 2560px) and (max-width: 3839px) {
  .container {
    width: 1440px;
  }
}
@media (min-width: 3840px) and (max-width: 10000px) {
  .container {
    width: 2560px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 16px;
  width: 100%;
}

.container .unityWrapper {
  position: relative;
  background: url("/public//images/bg-start-game-8-ball.png");
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Change to column layout */
  aspect-ratio: 3/2;
}

.unityWrapper .gameLogo {
  z-index: 1;
}

.container .unityWrapper .overlayContentWrapper::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(8, 24, 36, 0), rgba(8, 24, 36, 1));
}

.container .unityWrapper .powerText {
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #e3e6e8;
}
.container .unityWrapper .namePowerLogo {
  width: 144px;
  z-index: 1;
}
.container .unityWrapper .loadingWrapper {
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}
.loadingBarWrapper {
  background: transparent;
  width: 75%;
  height: 14px;
  border-radius: 8px;
  border: 1px solid #7ccffe;
  padding: 2px;
}

.container .unityWrapper .loadingBar {
  height: 8px;
  border-radius: 8px;
  background-color: #325366;
}

.container .unityWrapper .loadingBar .loadingBarFill {
  border-radius: 4px;
  height: 8px;
  background: #7ccffe;
  transition: 0.3s width ease-out;
}
.numberLoading {
  font-size: 16px;
  font-weight: 600;
  color: #7ccffe;
  line-height: 24px;
  margin-top: 8px;
  text-align: center;
}

.container .unityWrapper .loadingText {
  overflow: hidden;
  margin-top: 10px; /* Add top margin to separate the text and loading bar */
  text-align: center; /* Center-align text horizontally */
}

.container .unityWrapper canvas {
  width: 100%;
  aspect-ratio: 3/2;
}

.container .screenshots {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.container .buttonWrapper {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 32px;
}
.container .buttonWrapper .takeScreenShotButton:hover,
.container .buttonWrapper .fullScreenButton:hover {
  cursor: pointer;
  opacity: 0.9;
}
